<template>
  <div class="vue-daum-postcode">
    <div 
      ref="container" 
      class="vue-daum-postcode-container" />
  </div>
</template>

<script>
// https://github.com/wan2land/vue-daum-postcode
// http://postcode.map.daum.net/guide
function create(src, init) {
  let cache = null
  return () => cache ? cache : (cache = load(src, init))
}

function load(src, init) {
  return new Promise((resolve, reject) => {
    (document.head || document.body).appendChild(Object.assign(document.createElement("script"), {
      src,
      async: true,
      charset: "utf8",
      onload() {
        const result = init && init()
        result instanceof Promise ? result.then(resolve) : resolve()
      },
      onerror() {
        reject(new Error(`Failed to load ${src}`))
      },
    }))
  })
}

const options = {}

const loadDaumPostcode = create(
  options.scriptUrl || 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  () => {
    return new Promise(resolve => window.daum.postcode.load(resolve))
  },
)

export default {
  name: 'VueDaumPostcode',
  props: {
    q: {
      type: String,
      default: '',
    },
    animation: {
      type: Boolean,
      default: false,
    },
    noAutoMapping: {
      type: Boolean,
      default: false,
    },
    noShorthand: {
      type: Boolean,
      default: false,
    },
    pleaseReadGuide: {
      type: Number,
      default: 0,
    },
    pleaseReadGuideTimer: {
      type: Number,
      default: 1.5,
    },
    maxSuggestItems: {
      type: Number,
      default: 10,
    },
    showMoreHName: {
      type: Boolean,
      default: false,
    },
    hideMapBtn: {
      type: Boolean,
      default: false,
    },
    hideEngBtn: {
      type: Boolean,
      default: false,
    },
    alwaysShowEngAddr: {
      type: Boolean,
      default: false,
    },
    zonecodeOnly: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      styleHeight: 0,
    }
  },
  computed: {
    styles() {
      const styles = {}
      styles.height = this.styleHeight
      return styles
    },
  },
  mounted() {
    loadDaumPostcode().then(() => {
      new window.daum.Postcode({
        width: '100%',
        height: '100%',
        animation: this.animation,
        autoMapping: !this.noAutoMapping,
        shorthand: !this.noShorthand,
        pleaseReadGuide: this.pleaseReadGuide,
        pleaseReadGuideTimer: this.pleaseReadGuideTimer,
        maxSuggestItems: this.maxSuggestItems,
        showMoreHName: this.showMoreHName,
        hideMapBtn: this.hideMapBtn,
        hideEngBtn: this.hideEngBtn,
        alwaysShowEngAddr: this.alwaysShowEngAddr,
        zonecodeOnly: this.zonecodeOnly,
        theme: this.theme,
        oncomplete: (data) => {
          this.$emit('complete', data)
        },
        onresize: (size) => {
          this.styleHeight = `${size.height}px`
        },
      }).embed(this.$refs.container, {
        q: this.q,
        autoClose: false,
      })
    })
  },
}
</script>